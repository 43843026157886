'use client';

import Image from 'next/image';
import {
  Carousel,
  CarouselContent,
  CarouselThumb,
  CarouselItem,
  CarouselDot,
} from '../EmblaCarousel/EmblaCarousel';

function PreviewCarouselItem({ data }) {
  const {
    image: { _publishUrl: image = '' },
    imageWidth = 660,
    imageHeight = 760,
    imageAlt = '',
  } = data;

  return (
    <Image
      className='w-full mb-4 rounded-lg'
      src={image}
      width={imageWidth}
      height={imageHeight}
      alt={imageAlt}
    />
  );
}

function PreviewCarouselThumb({ data }) {
  const {
    image: { _publishUrl: image = '' },
    imageAlt = '',
  } = data;

  return <Image className='w-full mb-4 rounded' src={image} width='66' height='76' alt={imageAlt} />;
}

export default function PreviewCarousel({ items = [], showThumbs = false }) {
  return (
    <Carousel className='overflow-hidden'>
      <CarouselContent>
        {items.map((item) => (
          <CarouselItem key={item.imageAlt}>
            <PreviewCarouselItem data={item} />
          </CarouselItem>
        ))}
      </CarouselContent>

      {showThumbs ? (
        <div className='flex justify-center items-center gap-4'>
          {items.map((item, index) => (
            <CarouselThumb className='w-[60px]' key={item.title.plaintext} index={index}>
              <PreviewCarouselThumb data={item} />
            </CarouselThumb>
          ))}
        </div>
      ) : (
        <div className='flex justify-center items-center gap-2 px-4 h-6'>
          {items.map((item, index) => (
            <CarouselDot key={item.imageAlt} index={index} />
          ))}
        </div>
      )}
    </Carousel>
  );
}
