const monthNames = {
  1: 'One Month',
  2: 'Two Months',
  3: 'Three Months',
  4: 'Four Months',
  5: 'Five Months',
  6: 'Six Months',
  7: 'Seven Months',
  8: 'Eight Months',
  9: 'Nine Months',
  10: 'Ten Months',
  11: 'Eleven Months',
  12: 'One Year',
};

export default function getMonth(cardMonthValue) {
  if (Object.prototype.hasOwnProperty.call(monthNames, cardMonthValue)) {
    return monthNames[cardMonthValue];
  }
  return '';
}
