const currencyConversion = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  GBP: '£', // British Pound Sterling
};

// get the symbol for the currency, otherwise return undefined
export default function currencyConverter(currencyAbbreviation) {
  if (currencyConversion[currencyAbbreviation]) {
    return currencyConversion[currencyAbbreviation];
  }
  return undefined;
}
