'use client';

import { useState, useEffect } from 'react';

let OPTIONS = {
  root: null,
  rootMargin: '0px 0px 0px 0px',
  threshold: 0,
};

// defaultValue param added for cases when we should wait for elementRef to scroll into view and keep consistent value
const useIsVisible = (elementRef, options = {}, shouldUnobserve = true, defaultValue = false) => {
  OPTIONS = {
    ...OPTIONS,
    ...options,
  };

  const [isVisible, setIsVisible] = useState(defaultValue);

  useEffect(() => {
    if (elementRef.current) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            if (shouldUnobserve) observer.unobserve(elementRef.current);
          } else {
            setIsVisible(false);
          }
        });
      }, OPTIONS);
      observer.observe(elementRef.current);
    }
  }, [elementRef]);

  return isVisible;
};

export default useIsVisible;
