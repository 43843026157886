'use client';

import Image from 'next/image';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../EmblaCarousel/EmblaCarousel';
import cn from '../../lib/utils';

function DetailsCarouselItem({ data, rounded }) {
  const {
    image: { _publishUrl: image = '' },
    imageWidth = 297,
    imageHeight = 397,
    imageAlt = '',
    title: { plaintext: title = null },
    text: { plaintext: text = null },
  } = data;

  return (
    <>
      <Image
        className={cn(rounded && 'rounded-xl', 'w-full mb-4')}
        src={image}
        width={imageWidth}
        height={imageHeight}
        alt={imageAlt}
      />
      <h3 className='mb-2'>{title}</h3>
      <p className='opacity-70'>{text}</p>
    </>
  );
}

export default function DetailsCarousel({ items = [], rounded = false }) {
  return (
    <Carousel
      opts={{
        align: 'center',
      }}
    >
      <CarouselContent className='-ml-4 px-4 md:px-0 mb-10'>
        {items.map((item) => (
          <CarouselItem
            key={item.title.plaintext}
            className='basis-[87%] md:basis-1/3 pl-4 last:mr-4 last:md:mr-0'
          >
            <DetailsCarouselItem rounded={rounded} data={item} />
          </CarouselItem>
        ))}
      </CarouselContent>

      <div className='flex justify-end gap-2 px-4 md:px-0'>
        <CarouselPrevious />
        <CarouselNext />
      </div>
    </Carousel>
  );
}
