'use client';

import { useEffect } from 'react';

function setDigitalData(dataObject) {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push(dataObject);
}

export default function AdobeAnalyticsPageTracker({ data }) {
  useEffect(() => {
    setDigitalData(data);
  }, [data]);
}
