/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-props-no-spreading */

/*
 * This Carousel is a slightly modified copy from ShadCN UI Components
 * https://ui.shadcn.com/docs/components/carousel
 */

'use client';

import { createContext, forwardRef, useContext, useState, useCallback, useEffect } from 'react';
import { CaretLeftIcon, CaretRightIcon } from '@radix-ui/react-icons';
import useEmblaCarousel from 'embla-carousel-react';
import cn from '../../lib/utils'; //eslint-disable-line

const CarouselContext = createContext(null);

function useCarousel() {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }

  return context;
}

const Carousel = forwardRef(
  ({ orientation = 'horizontal', opts, setApi, plugins, className, children, ...props }, ref) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === 'horizontal' ? 'x' : 'y',
      },
      plugins,
    );
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const onSelect = useCallback(
      (cbApi) => {
        if (!cbApi) {
          return;
        }

        setSelectedIndex(cbApi.selectedScrollSnap());
        setCanScrollPrev(cbApi.canScrollPrev());
        setCanScrollNext(cbApi.canScrollNext());
      },
      [setSelectedIndex],
    );

    const scrollPrev = useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const scrollTo = useCallback(
      (index) => {
        api?.scrollTo(index);
      },
      [api],
    );

    const handleKeyDown = useCallback(
      (event) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext],
    );

    useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on('reInit', onSelect);
      api.on('select', onSelect);

      // eslint-disable-next-line consistent-return
      return () => {
        api?.off('select', onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api,
          opts,
          orientation: orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
          scrollPrev,
          scrollNext,
          scrollTo,
          canScrollPrev,
          canScrollNext,
          selectedIndex,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn('relative', className)}
          role='region'
          aria-roledescription='carousel'
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  },
);
Carousel.displayName = 'Carousel';

const CarouselContent = forwardRef(({ className, ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel();

  return (
    <div ref={carouselRef} className='overflow-hidden'>
      <div
        ref={ref}
        className={cn('flex', orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col', className)}
        {...props}
      />
    </div>
  );
});
CarouselContent.displayName = 'CarouselContent';

const CarouselItem = forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    role='group'
    aria-roledescription='slide'
    className={cn('min-w-0 shrink-0 grow-0 basis-full pl-4', className)}
    {...props}
  />
));
CarouselItem.displayName = 'CarouselItem';

const CarouselPrevious = forwardRef(({ className }, ref) => {
  const { scrollPrev, canScrollPrev } = useCarousel();

  return (
    <button
      ref={ref}
      className={cn(
        'size-12 rounded-full bg-brown-300 inline-flex justify-center items-center disabled:opacity-45',
        className,
      )}
      disabled={!canScrollPrev}
      onClick={scrollPrev}
      type='button'
    >
      <CaretLeftIcon className='h-8 w-8' />
      <span className='sr-only'>Previous slide</span>
    </button>
  );
});
CarouselPrevious.displayName = 'CarouselPrevious';

const CarouselNext = forwardRef(({ className }, ref) => {
  const { scrollNext, canScrollNext } = useCarousel();

  return (
    <button
      ref={ref}
      className={cn(
        'size-12 rounded-full bg-brown-300 inline-flex justify-center items-center disabled:opacity-45',
        className,
      )}
      disabled={!canScrollNext}
      onClick={scrollNext}
      type='button'
    >
      <CaretRightIcon className='h-8 w-8' />
      <span className='sr-only'>Next slide</span>
    </button>
  );
});
CarouselNext.displayName = 'CarouselNext';

const CarouselDot = forwardRef(({ className, index }, ref) => {
  const { scrollTo, selectedIndex } = useCarousel();

  return (
    <button
      ref={ref}
      className={cn(
        'size-2.5 bg-gray-300 rounded-xl transition-all duration-300',
        className,
        index === selectedIndex && 'size-4 bg-gray-600',
      )}
      onClick={() => scrollTo(index)}
      type='button'
      selected={index === selectedIndex}
    >
      {' '}
    </button>
  );
});
CarouselNext.displayName = 'CarouselDot';

const CarouselThumb = forwardRef(({ children, className, index }, ref) => {
  const { scrollTo, selectedIndex } = useCarousel();

  return (
    <button
      ref={ref}
      className={cn(
        'transition-all duration-300 opacity-50',
        className,
        index === selectedIndex && 'opacity-100',
      )}
      onClick={() => scrollTo(index)}
      type='button'
      selected={index === selectedIndex}
    >
      {children}
    </button>
  );
});
CarouselNext.displayName = 'CarouselThumb';

export {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
  CarouselDot,
  CarouselThumb,
};
